<template>
  <v-icon
    v-if="canBeClosed"
    v-bind="$attrs"
    class="cursor-pointer"
    @click="closeWindow()"
    :color="color"
    >mdi-close</v-icon
  >
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black'
    }
  },
  computed: {
    canBeClosed() {
      return window.opener
    }
  },
  methods: {
    closeWindow() {
      window.close()
    }
  }
}
</script>

<style></style>
